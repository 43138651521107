<template>
  <locale-router-link
    v-if="item"
    :to="`products/${item.id}`"
    class="product-block"
    ><span class="product-block__header"
      ><span v-if="item.attributes.discount" class="product-block__stock"
        >-{{ item.attributes.discount }}%</span
      ><span class="product-block__date">до {{ getDate(item) }}</span></span
    >
    <span class="product-block__content">
      <span class="product-block__left">
        <span class="product-block__img">
          <img v-lazy="{ src: imageUrl, loading: imageLoading }" alt="image" />
        </span>
      </span>

      <span class="product-block__right">
        <!-- <span class="">{{ item.attributes.shopTypes }}</span> -->
        <!-- <span class="">{{ item.attributes.discount_type }}</span> -->

        <p v-if="item.attributes.name" class="product-block__descr">
          <span>
            {{ item.attributes.name }}
          </span>
          <span v-if="item.attributes.country">
            {{ item.attributes.country }}
          </span>
        </p>
        <span class="product-block__price-wrapper">
          <span
            v-if="item.attributes.old_price"
            class="product-block__old-price"
            >{{ item.attributes.old_price }} тг</span
          >
          <span v-if="item.attributes.new_price" class="product-block__price"
            >{{ item.attributes.new_price }} тг</span
          >
        </span>
      </span>
    </span>
  </locale-router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log("item", this.item);
  },
  computed: {
    imageUrl() {
      if (this.item.attributes?.image?.data?.length) {
        return this.$helpers.getAbsolutePath(
          this.item.attributes?.image?.data[0].attributes?.url
        );
      }
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
    imageLoading() {
      return require("@/" + "assets/img/icons/placeholder.svg");
    },
  },
  methods: {
    getDate(item) {
      const dateFromStr = new Date(item.attributes?.action_end);
      return new Intl.DateTimeFormat("ru-Ru", {
        // year: "numeric",
        month: "numeric",
        day: "numeric",
      }).format(dateFromStr);
    },
  },
};
</script>
<style lang="scss" scoped>
.product-block {
  position: static !important;
  // width: auto !important;
  border: none;
  height: auto;
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: space-around;
  &__descr {
    min-height: 80px;
    font-family: "CeraPro Regular";
    // max-width: 200px;
    // overflow: hidden;
    width: 100%;
    font-size: 16px;
    // line-height: 110%;
    color: #2d2f32;
  }
}
.product-block__content {
  // position: relative;
}
.product-block__price-wrapper {
  position: static;

  margin-top: auto;
}
</style>
